.SoundsCon {
    display: flex;
    flex-direction: column;
  }
  
  .Sounds {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.5rem;
  }
  
  .Sounds p {
    font-size: 1rem; /* Adjust font size for mobile */
    color: white;
  }
  
  .Sounds p span {
    color: #2c94d6;
  }
  
  .SoundsBtn {
    background: transparent;
    border: 2px solid #2c94d6;
    cursor: pointer;
    outline: none;
    font-size: inherit;
    color: #2c94d6;
    padding: 0.3rem 0.6rem; /* Adjust padding for mobile */
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    flex: 0 0 calc(50% - 0.5rem); /* Two buttons per row on mobile with gap of 0.5rem */
    margin-bottom: 0.5rem;
  }
  
  .SoundsBtn:hover {
    background: #2c94d6;
    color: white;
  }
  
  .NameCon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem; /* Adjust margin for mobile */
    margin-bottom: 0.5rem;
  }
  
  .NameCon h2 {
    font-size: 1.5rem; /* Adjust font size for mobile */
    font-weight: 500;
    color: white;
  }
  