* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #0d0d1b;
  background-image: url(../src/img/bg.webp);
  font-size: 1.2rem;
  font-family: 'Cubano', sans-serif;
}

.App {
  margin: 2rem;
}

.logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.logo img {
  width: 100%;
  max-width: 500px; /* Limit logo size on larger screens */
}

.characters-con {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive grid */
  grid-gap: 1rem;
}

.characters-con li {
  border-radius: 10px;
  cursor: pointer;
}

.characters-con .characters {
  height: 200px; /* Adjust height for smaller screens */
}

.characters-con li img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: auto;
  aspect-ratio: 1/1;
}

.active {
  position: relative;
}

.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.active::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 5px solid #2C94D6;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Media queries for responsiveness */

@media screen and (min-width: 768px) {
  .App {
    margin: 2rem auto;
    max-width: 800px; /* Limit app width on larger screens */
  }
}

@media screen and (max-width: 767px) {
  .logo img {
    width: 80%; /* Adjust logo size for smaller screens */
  }

  .characters-con {
    grid-template-columns: repeat(2, 1fr); /* Adjust grid for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .characters-con {
    grid-template-columns: 1fr; /* Display characters in a single column on smaller screens */
  }
}